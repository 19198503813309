import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleRequest = () => {
    navigate("/RequestDemo");
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center p-4">
        <Link to="/">
          <img src={logo} alt="Sharkpe" className="h-10" />
        </Link>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-black flex flex-col items-center justify-center space-y-1 focus:outline-none"
          >
            <div className="w-6 h-0.5 bg-black"></div>
            <div className="w-6 h-0.5 bg-black"></div>
            <div className="w-6 h-0.5 bg-black"></div>
          </button>
        </div>

        <div className="hidden lg:flex gap-14 items-center">
          <a href="/" className="text-black m-1" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>Home</a>
          <Link to="/AboutUsPage" className="text-black m-1" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
                About Us
            </Link>
            <Link to="/ContactUs" className="text-black m-1" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>Contact Us
            </Link>
        
        </div>
        <div className="hidden lg:flex gap-14 items-center">
        <button className="bg-blue-900 text-white px-6 py-1 rounded-md" style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }} onClick={handleRequest}>Request Demo</button>

        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } lg:hidden bg-white shadow-md transition duration-300 ease-in-out`}
      >
        <div className="flex flex-col items-center gap-4 py-4">
          <Link
            to="/"
            className="text-black font-medium hover:text-blue-900"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/AboutUsPage"
            className="text-black font-medium hover:text-blue-900"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            About Us
          </Link>
          <Link
            to="/ContactUs"
            className="text-black font-medium hover:text-blue-900"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Contact Us
          </Link>
          <button
            className="bg-blue-900 text-white px-6 py-1 rounded-md hover:bg-blue-800"
            onClick={() => {
              handleRequest();
              setIsMobileMenuOpen(false);
            }}
          >
            Request Demo
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import RequestDemo from './pages/RequestDemo';
import Optimize from './pages/Optimize';
import ManagePage from './pages/ManagePage';
import Developer from './pages/Developer';
import CreateAccount from './pages/CreateAccount';
import PrivacyPolice from './pages/PrivacyPolice';
import TermsConditions from './pages/TermsConditions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/AboutUsPage" element={<AboutUsPage />} />
        <Route path="/ContactUs" element={<ContactUsPage />} />
        <Route path="/Optimize" element={<Optimize />} />
        <Route path="/RequestDemo" element={<RequestDemo />} />
        <Route path="/ManagePage" element={<ManagePage />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/Develop" element={<Developer />} />
        <Route path="/privacy-policy" element={<PrivacyPolice />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
      </Routes>
    </Router>
  );
}

export default App;


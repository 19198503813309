import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import faq from '../assets/faq.png';

function FrequentlyQus() {
  return (
    <>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12 px-4 sm:px-6 lg:px-8 py-10 bg-gradient-to-r from-purple-300 via-purple-400 to-blue-500">
        <div className="p-6 rounded-lg text-left flex flex-col justify-center">
          <h3 className="text-4xl font-bold text-purple-900 mb-2">Frequently Asked Questions</h3>
          <p className="text-white text-lg mb-6">Have more questions?</p>
          <button className="text-green-300 text-lg font-semibold mt-4 flex items-center space-x-2 hover:text-green-400">
            <span>Visit our support page</span>
            <IoIosArrowForward />
          </button>
        </div>
        <div className=" rounded-lg transition duration-300 cursor-pointer p-4">
          <img
            src={faq}
            alt="FAQ"
            className="  hover:scale-105 transition-transform duration-300 rounded-lg"
          />
        </div>
      </section>
      <div className="p-6 bg-white rounded-lg shadow-lg flex flex-col sm:flex-row ">
        <div className='w-[100%] px-4 sm:px-6 lg:px-8'>
          <h5 className="text-xl font-semibold text-blue-900 mb-4">Why should I choose Sharkpe Payments?</h5>
          <p className="text-gray-700 mb-3">
            Sharkpe Payments is India’s leading payments and API banking company. It helps 6,00,000+ businesses accept and send money and is used for multiple use cases like vendor payouts, wage payouts, bulk refunds, etc.
          </p>
          <p className="text-gray-700 mb-3">
            Sharkpe Payments offers a wide range of solutions including an API banking platform that helps businesses send money instantly 24x7 to any bank account, UPI ID, card, or wallet with a simple API that businesses can integrate with their product.
          </p>
          <p className="text-gray-700 mb-3">
            Sharkpe Payments also provides an advanced payment gateway with features like instant refunds, card-preauthorization, and more.
          </p>
          <p className="text-gray-700 mb-3">
            Sharkpe Payments is integrated with major platforms like Shopify, PayPal, Amazon Pay, Google Pay, and Ola Money among others.
          </p>
          <a href="#" className="text-purple-600 font-semibold inline-flex items-center mt-4">
            Read More
          </a>
        </div>
        <div className="p-6 col-span-2 border w-[100%] bg-gray-200 px-4 sm:px-6 lg:px-8">
          <ul className="space-y-6 ">
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>How to get started on Sharkpe Payments?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>How long would it take to activate my account?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>How is Sharkpe Payment Gateway different from others in India?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>Can I start accepting international payments using Sharkpe Payments?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>What is Payouts?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
            <li className="flex justify-between items-center text-blue-900 font-medium border-b border-gray-300 pb-3 cursor-pointer hover:bg-gray-300 hover:text-purple-600 transition duration-300 ease-in-out">
              <span>Can I get tailored pricing for my business?</span>
              <AiOutlinePlus className="text-purple-500" />
            </li>
          </ul>
        </div>

      </div></>

  );
}

export default FrequentlyQus;

import React, { useEffect } from 'react';
import Button from '../components/Button';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Animation from '../components/Animation';
import Logos from '../components/Logos';
import OurProduct from '../pages/OurProduct';
import PaymentSection from '../pages/PaymentSection';
import FrequentlyQus from './FrequentlyQus';
import AOS from 'aos'; 
import 'aos/dist/aos.css'; 
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleRequest = () => {
    navigate("/RequestDemo");
  };
  const handleContact = () => {
    navigate("/ContactUs");
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: true, 
    });
  }, []);

  return (
    <>
      <Animation />
      <Navbar />

      <section
        className="bg-[#6933D3] text-white py-16 w-full pt-[120px] px-4 sm:px-6 md:px-8"
        data-aos="fade-up" 
      >
        <div className="container mx-auto text-left">
          <h1
            className="text-3xl sm:text-4xl lg:text-5xl font-bold text-blue-900"
            data-aos="fade-right" 
          >
            Payment Orchestration
          </h1>
          <h1
            className="text-3xl sm:text-4xl lg:text-5xl font-bold mt-2"
            data-aos="fade-left" 
          >
            Platform for All Types of Business
          </h1>
          <p
            className="mt-4 text-base sm:text-lg md:text-xl text-balance line-clamp-4"
            data-aos="fade-up" 
          >
            Sharkpe is a universal feature-rich payment orchestration platform for online businesses and payment institutions. We integrate payment providers and acquirers to bring a unified communication, control, and management interface.
          </p>
          <div
            className="mt-6 flex flex-col sm:flex-row sm:justify-start gap-4"
            data-aos="fade-up"
          >
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-md mb-4 sm:mb-0"
              style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }} onClick={handleRequest}
            >
              Request Demo
            </button>
            <button className="border-blue-900 bg-white text-purple-600 rounded-md px-4 py-2" onClick={handleContact}>
              Contact Us
            </button>
          </div>
        </div>
      </section>

      <Logos />

      <section className="container mx-auto my-12 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl text-[#6933D3] font-semibold text-center mb-8">
          What is Sharkpe?
        </h2>
        <p className="text-center mb-4 text-sm sm:text-base md:text-lg">
          Sharkpe is an all-inclusive and feature-packed payment orchestration platform designed for OMNI businesses and payment institutions. Our platform integrates payment providers and acquirers from all over the world, offering a unified communication, control, and management interface.
        </p>
        <Button onClick={handleRequest}>Request Demo</Button>
      </section>

      <OurProduct />
      <PaymentSection />
      <FrequentlyQus />
      <Footer />
    </>
  );
};

export default HomePage;


import React from 'react'
import { FaArrowRightToBracket } from 'react-icons/fa6'
import Dashboard from '../assets/Dashboard.png';
import PG from '../assets/PG.png';
import Insights from '../assets/Insights.png';
import transaction from '../assets/transaction.png';

function ManagePage() {
    return (
        <section className="bg-gray-50 py-12 px-6 md:px-16">
            <h2 className="text-4xl font-bold text-center px-2  py-2 bg-gray-600 border border-black text-white rounded-3xl shadow-md shadow-gray-400 mb-12">
                Manage
            </h2>
            <section className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-12">
                <div className="p-6 rounded-lg text-left flex items-center gap-6">
                    <img
                        src={Dashboard}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Dashboard</h3>
                        <p className="text-gray-600 mb-4">
                            Access all your online and offline transactions through a single dashboard. Our intelligent dashboard allows you to monitor OMNI channel payments from a centralized location, providing you with a comprehensive 360-degree view of your business.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>

                <div className="p-6 text-left flex items-center gap-6">
                <img
                        src={transaction}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Complete transaction details</h3>
                        <p className="text-gray-600 mb-4">
                            All information collected by your payment gateway/payment aggregator (PG/PA) is presented to you in a comprehensive transaction overview. This data can also be downloaded as management information system (MIS) or automatically generated at the end of each month.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>

                <div className="p-6 text-left flex items-center gap-6">
                <img
                        src={PG}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">All PG/PA management</h3>
                        <p className="text-gray-600 mb-4">
                            Manage all your payment gateways/payment aggregators (PGs/PAs) from a centralized location. This feature provides you with more flexibility, such as the ability to enable or disable payment methods and process refunds from a single location.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>


                <div className="p-6 text-left flex items-center gap-6">
                <img
                        src={Insights}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Data Insights</h3>
                        <p className="text-gray-600 mb-4">
                        Gain valuable insights and effortlessly track key performance indicators with our Data Insights feature. Effortlessly locate specific transactions with our easy-to-use search parameters. Manage accounts, monitor integrations, handle payments and refunds, and more, all within one powerful interface for comprehensive data management.                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default ManagePage
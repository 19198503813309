import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import payment from '../assets/payment.png';
import link from '../assets/links.png';
import collect from '../assets/collect.png';
import vedio from '../assets/Video.mp4';
import { useNavigate } from 'react-router-dom';

function OurProduct() {
    const navigate = useNavigate();

    const handleCreateAccount = () => {
      navigate("/CreateAccount");
    };

    return (
        <div className="bg-white p-6 md:flex md:items-center md:justify-center">
            <div className="container mx-auto px-4 lg:flex lg:space-x-8">
            <div className="lg:w-1/2 mb-6 lg:mb-0">
    <video
        src={vedio}
        className="w-full h-auto rounded-lg"
        controls
        autoPlay
        muted
        loop
    >
        Your browser does not support the video tag.
    </video>
</div>

                <div className="lg:w-1/2 text-left  overflow-y-scroll max-h-[500px] p-5 rounded-[2%]">
                    <div className="space-y-4">
                        <h2 className="text-green-600 font-semibold">OUR PRODUCTS</h2>
                        <h1 className="text-3xl font-bold text-gray-900 lg:text-4xl">
                            One platform for all your payment and banking needs
                        </h1>
                        <p className="text-gray-700">
                            Sharkpe Payments is built to handle the unique payment needs of your business.
                            Whether you need to collect payments or make on-demand payouts, Sharkpe Payments
                            is all you will ever need.
                        </p>
                        <div className="flex space-x-4">
                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-6 flex items-center space-x-2">
                                <button onClick={handleCreateAccount}>Create Account</button>
                                <IoIosArrowForward />
                            </button>
                        </div>
                    </div>

                    {/* Scrollable Section */}
                    <div className="mt-8 space-y-8 max-h-[400px] pr-2">
                        <div>
                            <h3 className="text-xl font-semibold text-black flex items-center space-x-2">
                                <span role="img" aria-label="icon">📥</span>
                                <span>Collect payments</span>
                            </h3>
                            <p className="text-gray-600 mt-2">
                                With Sharkpe Payments, you can collect online payments on your website or app, across multiple channels, effortlessly.
                            </p>
                            <div className="flex space-x-2 mt-8">
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    Payment Gateway
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={link} alt="payment" className="w-4 h-4 mr-1" />
                                    Payment Links
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={collect} alt="payment" className="w-4 h-4 mr-1" />
                                    Auto Collect
                                </span>
                            </div>

                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-6 flex items-center space-x-2">
                            <button onClick={handleCreateAccount}>Create Account</button>
                                <IoIosArrowForward />
                            </button>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black flex items-center space-x-2">
                                <span role="img" aria-label="icon">🔄</span>
                                <span>Recurring payments</span>
                            </h3>
                            <p className="text-gray-600 mt-2">
                                Automate recurring payments and reduce subscription churn by offering your customers
                                subscription plans and auto-charge future payments - periodic or on-demand, with one-time
                                authentication, with Sharkpe Payments.
                            </p>
                            <div className="flex space-x-2 mt-8">
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    Subscriptions
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={link} alt="payment" className="w-4 h-4 mr-1" />
                                    UPI AutoPay
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={collect} alt="payment" className="w-4 h-4 mr-1" />
                                    eNach
                                </span>
                            </div>
                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-6 flex items-center space-x-2">
                            <button onClick={handleCreateAccount}>Create Account</button>
                                <IoIosArrowForward />
                            </button>
                        </div>

                        {/* Additional Sections with Scroll */}
                        <div>
                            <h3 className="text-xl font-semibold text-black flex items-center space-x-2">
                                <span role="img" aria-label="icon">🔄</span>
                                <span>Make payouts</span>
                            </h3>
                            <p className="text-gray-600 mt-2">
                                Pay vendors, make customer refunds, disburse employee salaries, and more with Sharkpe Payments’ APIs.
                            </p>
                            <div className="flex space-x-2 mt-8">
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-2 py-1 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    Payouts
                                </span>
                            </div>
                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-6 flex items-center space-x-2" onClick={handleCreateAccount}>
                                <span>Create Account</span>
                                <IoIosArrowForward />
                            </button>
                        </div>

                        <div>
                            <h3 className="text-xl font-semibold text-black flex items-center space-x-2">
                                <span role="img" aria-label="icon">🔄</span>
                                <span>Verify identity</span>
                            </h3>
                            <p className="text-gray-600 mt-2">
                                Know Your Customer by automating bank account, Aadhaar, and PAN verification, and more.
                            </p>
                            <div className="flex flex-wrap gap-2 mt-8">
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    Bank Account Verification
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    PAN Verification
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    Aadhaar Verification
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    GSTIN Verification
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    IFSC Verification
                                </span>
                                <span className="flex items-center bg-[#F6F6FE] text-purple-700 px-3 py-2 rounded">
                                    <img src={payment} alt="payment" className="w-4 h-4 mr-1" />
                                    UPI ID Verification
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurProduct;

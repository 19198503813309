import React from 'react';
import plural from '../assets/plural.png';
import Pinelabs from '../assets/Pinelabs.png';
import Coingate from '../assets/Coingate.png';
import Billdesk from '../assets/Billdesk.png';
import Braintree from '../assets/Braintree.png';


function Logos() {
  return (
    <div className="logos-container">
      <div className="logos-scroll">
        <img src={plural} alt="Logo 1" className="logo" />
        <img src={Pinelabs} alt="Logo 2" className="logo" />
        <img src={Coingate} alt="Logo 3" className="logo" />
        <img src={Billdesk} alt="Logo 4" className="logo" />
        <img src={Braintree} alt="Logo 5" className="logo" />
        {/* Duplicate images for seamless scrolling */}
        <img src={plural} alt="Logo 1" className="logo" />
        <img src={Pinelabs} alt="Logo 2" className="logo" />
        <img src={Coingate} alt="Logo 3" className="logo" />
        <img src={Billdesk} alt="Logo 4" className="logo" />
        <img src={Braintree} alt="Logo 5" className="logo" />
      </div>
    </div>
  );
}

export default Logos;

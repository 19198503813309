import React from "react";
import Navbar from "../components/Navbar";
import about from '../assets/about.jpg';

function AboutUsPage() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Navbar />
      
      {/* Hero Section */}
      <div className="bg-[#6933D3] text-white text-center py-20 px-4">
        <h1 className="text-4xl md:text-5xl font-bold animate-fade-in">
          About Us
        </h1>
        <h2 className="text-4xl md:text-5xl font-bold mt-4 text-pink-300 animate-fade-in">
          Who We Are?
        </h2>
        <p className="mt-4 text-lg md:text-xl max-w-2xl mx-auto animate-fade-in">
          SharkPe is a top B2B payment gateway provider. We make global transactions easy & secure with cryptocurrency & fiat options. 
          User-friendly, easily integrable, and backed by expert customer support. Trust us to keep your transactions fast, efficient, 
          and secure. Partner with SharkPe for a better business experience. Focus on growing your business while we handle payment processing.
        </p>
      </div>

      {/* About Image Section */}
      <div className="pt-4 w-full flex justify-center">
        <img
          src={about}
          alt="About Us"
          className="w-full max-w-4xl h-auto rounded-lg shadow-md hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer"
        />
      </div>

      {/* Mission Section */}
      <section className="py-16 px-4 max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 text-center animate-slide-up">
          Our Mission
        </h2>
        <p className="mt-6 text-lg text-gray-600 text-center max-w-4xl mx-auto animate-slide-up">
          At SharkPe, our mission is to innovate, inspire, and provide exceptional value. We are driven by a passion for excellence 
          and a commitment to making a positive impact on the world.
        </p>
      </section>

      {/* Core Values Section */}
      <section className="py-16 px-4 max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-8 animate-slide-up">
          Our Core Values
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {["Communicative", "Smart People", "Strategy"].map((value, index) => (
            <div
              key={index}
              className="text-center animate-slide-up delay-[${index * 200}ms]"
            >
              <h3 className="text-xl font-semibold text-gray-800">{value}</h3>
              <p className="mt-4 text-gray-600">
                {value === "Communicative" && 
                  "SharkPe prioritizes clear communication to ensure a seamless transaction process. Our platform is user-friendly and backed by expert customer support."
                }
                {value === "Smart People" && 
                  "Our team is composed of payment experts who are dedicated to making global transactions easy and secure. Partner with SharkPe and let our smart people handle your transactions."
                }
                {value === "Strategy" && 
                  "SharkPe takes a strategic approach to payments. Our platform integrates cryptocurrency and fiat options to address your business's specific payment needs."
                }
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-[#6933D3] text-white text-center py-6">
        <p>&copy; 2024 SharkPe. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default AboutUsPage;

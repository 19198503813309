import React from 'react';

const Button = ({ children, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`bg-blue-900 px-4 sm:px-6 lg:px-8 text-white  py-2 rounded-md font-bold ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function PrivacyPolice() {
  return (
    <><Navbar /><div className="container mx-auto p-6 max-w-4xl">

          <h1 className="text-3xl font-bold mb-6 text-blue-800 text-center">Privacy Policy</h1>
          <p className="text-sm text-gray-500 mb-8">Effective Date: Nov 20, 2024</p>
          <p className="mb-6 text-gray-700 leading-relaxed">
              At Sharkpe.in, your privacy is our priority. This Privacy Policy outlines how we collect, use, and safeguard your
              personal information when you interact with our website and services. By using our website, you agree to the practices
              described in this policy.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Information We Collect</h2>
          <ul className="list-disc ml-6 mb-6 text-gray-700 space-y-2">
              <li>
                  <strong>Personal Information:</strong> Name, email address, phone number, and other details provided during
                  registration or interaction with our website.
              </li>
              <li>
                  <strong>Transaction Information:</strong> Details of payments and transactions conducted on our platform.
              </li>
              <li>
                  <strong>Technical Information:</strong> IP address, browser type, device type, operating system, and browsing behavior.
              </li>
              <li>
                  <strong>Cookies and Tracking Data:</strong> Information collected through cookies and similar technologies for
                  website functionality and analytics.
              </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. How We Use Your Information</h2>
          <ul className="list-disc ml-6 mb-6 text-gray-700 space-y-2">
              <li>Provide and improve our services.</li>
              <li>Process transactions securely.</li>
              <li>Respond to your queries and provide customer support.</li>
              <li>Send promotional and marketing materials (you may opt out anytime).</li>
              <li>Ensure website security and prevent fraud.</li>
              <li>Comply with legal obligations.</li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Sharing Your Information</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              We do not sell or rent your personal data. However, we may share your information with:
          </p>
          <ul className="list-disc ml-6 mb-6 text-gray-700 space-y-2">
              <li>
                  <strong>Service Providers:</strong> To facilitate services like payment processing, hosting, or analytics.
              </li>
              <li>
                  <strong>Legal Authorities:</strong> When required by law or to protect our rights and safety.
              </li>
              <li>
                  <strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets, your data may be
                  transferred to the new entity.
              </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Cookies Policy</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              We use cookies to enhance user experience, analyze website traffic, and enable essential functionalities. You can manage
              or disable cookies through your browser settings.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Data Security</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              We implement robust security measures to protect your personal information. However, no method of transmission over the
              Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Your Rights</h2>
          <ul className="list-disc ml-6 mb-6 text-gray-700 space-y-2">
              <li>Access, correct, or delete your personal information.</li>
              <li>Opt out of marketing communications.</li>
              <li>Request restrictions on data processing where applicable.</li>
          </ul>
          <p className="mb-6 text-gray-700">
              To exercise your rights, contact us at <strong>info@sharkpe.in</strong>.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Third-Party Links</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites
              and encourage you to read their privacy policies.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Children’s Privacy</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              Our services are not directed at children under 13 years of age. We do not knowingly collect personal information from
              children.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Changes to This Privacy Policy</h2>
          <p className="mb-6 text-gray-700 leading-relaxed">
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised "Effective
              Date." We encourage you to review this page periodically.
          </p>

          <h2 className="text-2xl font-semibold mb-4 text-gray-800">10. Contact Us</h2>
          <p className="mb-4 text-gray-700 leading-relaxed">
              If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <ul className="list-none mb-6 text-gray-700">
              <li><strong>Email:</strong> info@sharkpe.in</li>
              <li><strong>Phone:</strong> +919717664203</li>
          </ul>
      </div>
      <Footer/></>
  );
}

export default PrivacyPolice;

import React from 'react';

function Animation() {
  return (
    <section className="bg-[#6933D3] text-white py-2 overflow-hidden">
      <h1 className="text-white text-center font-semibold text-lg md:text-xl lg:text-lg relative">
        <div className="flex items-center gap-14 sm:gap-4">
          <span className="animate-marquee whitespace-nowrap">
            This festive season 4X your online business with Sharkpe's One Click Checkout.
          </span>
          <span className="animate-marquee whitespace-nowrap absolute left-full">
            This festive season 4X your online business with Sharkpe's One Click Checkout.
          </span>
        </div>
      </h1>
    </section>
  );
}

export default Animation;

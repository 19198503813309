import React from 'react';
import Sdk from '../assets/Sdk.png'
import Extensions from '../assets/Extensions.png'
import api from '../assets/api.png'


function Developer() {
  return (
    <section className="bg-gray-50 py-16 px-8 md:px-20">
      {/* Develop Button */}
      <h2 className="text-center px-6 py-2 w-36 mx-auto bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg">
        Develop
      </h2>

      {/* Content Sections */}
      <div className="mt-12 space-y-16 ">
        {/* SDKs Section */}
        <div className="flex items-start gap-6 m-16">
          <img
            src={Sdk}
            alt="SDK Icon"
            className="w-12 h-12 mt-1"
          />
          <div>
            <h3 className="text-xl font-semibold text-black mb-2">SDKs</h3>
            <hr className="my-8 border-gray-300" />
         <div className='mt-8 text-center'>
         <p className="text-gray-800  dark:text-gray-700 mb-4">
              Our SDKs make it easier to integrate payment functionality into
              your platforms. These SDKs provide pre-built code and libraries
              that can be easily integrated into an application, reducing the
              time and effort required to build payment functionality from
              scratch.
            </p>
         </div>
          </div>
        </div>

        {/* Extensions Section */}
        <div className="flex items-start gap-6 m-16">
          <img
            src={Extensions}
            alt="Extensions Icon"
            className="w-12 h-12 mt-1"
          />
          <div>
            <h3 className="text-xl font-semibold text-black mb-2">Extensions</h3>
            <hr className="my-8 border-gray-300" />
            <p className="text-gray-700">
              Our payment extensions can be used to add new payment
              functionality to your applications. These extensions can be used
              to customize the payment experience for customers, provide new
              payment options, and more.
            </p>
          </div>
        </div>

        {/* API Playground Section */}
        <div className="flex items-start gap-6 m-16">
          <img
            src={api}
            alt="API Playground Icon"
            className="w-12 h-12 mt-1"
          />
          <div>
            <h3 className="text-xl font-semibold text-black mb-2">
              API Playground
            </h3>
            <hr className="my-8 border-gray-300" />
            <p className="text-gray-700 mb-2">
              Our API Playground can be used to test and explore payment
              functionality without having to write any code. This can be
              useful for integrating payment functionality into your
              applications, as it allows you to quickly and easily test
              different scenarios and validate the functionality of the payment
              API.
            </p>
            <a
              href="#"
              className="text-purple-600 font-semibold inline-flex items-center"
            >
              Read More <span className="ml-2">→</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Developer;

import React from "react";
import Reconciliations from '../assets/Reconciliations.png';
import Routes from '../assets/Routes.png';
import Plugin from '../assets/Plugin.png';
import { FaArrowRightToBracket } from "react-icons/fa6";

function Optimize() {
    return (
        <><section className="bg-gray-50 py-12 px-6 md:px-16">
            <h2 className="text-center px-4 py-2 w-28 mx-auto bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg">
                Optimize
            </h2>
            <section className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-12 text-center">
                <div className="p-6 rounded-lg text-left flex items-center gap-6">
                    <img
                        src={Routes}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />

                    <div>
                        <h3 className="text-xl font-semibold mb-4">Payment Providers</h3>
                        <p className="text-gray-600 mb-4 ">
                            You can easily choose and connect with any payment service provider (PSP) in just a few clicks. Our platform
                            currently supports over 25+ payment gateways, both domestic and international, and we are continually
                            adding more options.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>

                <div className="p-6 text-left flex items-center gap-6 flex-row-reverse">
                <img
                        src={Plugin}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Payment Providers</h3>
                        <p className="text-gray-600 mb-4">
                            You can easily choose and connect with any payment service provider (PSP) in just a few clicks. Our platform
                            currently supports over 25+ payment gateways, both domestic and international, and we are continually adding more options.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>



                <div className="p-6 text-left flex items-center gap-6">
                <img
                        src={Reconciliations}
                        alt="Route Representation"
                        className="w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                    />
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Payment Methods</h3>
                        <p className="text-gray-600 mb-4">
                            Our payment acceptance capabilities span across the globe with
                            support for over 100 payment methods. This allows organizations
                            to dynamically enable, disable, or prioritize payment methods in
                            real-time.
                        </p>
                        <a href="#" className="text-purple-600 font-semibold inline-flex items-center">
                            Read More <FaArrowRightToBracket className="ml-2" />
                        </a>
                    </div>
                </div>

            </section>
        </section></>

    );
}

export default Optimize;




import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function TermsConditions() {
  return (
    <><Navbar /><div className="container mx-auto p-6 max-w-4xl">
      <h1 className="text-3xl font-bold mb-4 text-blue-800 text-center">Terms and Conditions</h1>
      <p className="text-sm text-gray-500 mb-6">Effective Date: Nov 20, 2024</p>

      <p className="mb-4">
        Welcome to Sharkpe.in. These Terms and Conditions govern your use of our website and services. By accessing or using our platform, you agree to comply with these terms. If you do not agree, please discontinue use immediately.
      </p>

      <h2 className="text-2xl font-semibold mb-3">1. Definitions</h2>
      <ul className="list-disc ml-6 mb-4">
        <li><strong>“We,” “Us,” or “Our”:</strong> Refers to Sharkpe.in, the website and its operators.</li>
        <li><strong>“User,” “You,” or “Your”:</strong> Refers to any individual or entity accessing our website or services.</li>
        <li><strong>“Services”:</strong> Refers to the features, tools, and functionality provided through Sharkpe.in.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">2. Eligibility</h2>
      <p className="mb-4">
        By using Sharkpe.in, you confirm that:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>You are at least 18 years old or have legal parental/guardian consent to use our services.</li>
        <li>You have the authority to agree to these Terms and Conditions on behalf of any entity you represent.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">3. Use of Our Services</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>You must use our website for lawful purposes only.</li>
        <li>You agree not to engage in activities that disrupt or harm the website, its users, or our infrastructure.</li>
        <li>You are responsible for maintaining the confidentiality of your account information.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">4. User Accounts</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>To access certain features, you may need to create an account.</li>
        <li>You agree to provide accurate and current information during registration.</li>
        <li>We reserve the right to suspend or terminate your account for any unauthorized use or breach of these terms.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">5. Payments and Transactions</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>All payments made through Sharkpe.in are subject to our Policy.</li>
        <li>You agree to provide accurate billing information and authorize us to process payments as per the selected services.</li>
        <li>We are not liable for delays, errors, or failures caused by third-party payment processors.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">6. Intellectual Property</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>All content on Sharkpe.in, including logos, graphics, text, and software, is the property of Sharkpe.in or its licensors.</li>
        <li>You may not reproduce, distribute, or modify any content without our prior written consent.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">7. Prohibited Activities</h2>
      <p className="mb-4">
        You agree not to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Use the platform for fraudulent, abusive, or illegal activities.</li>
        <li>Upload viruses, malware, or harmful code.</li>
        <li>Reverse-engineer, scrape, or exploit our platform without authorization.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-3">8. Limitation of Liability</h2>
      <p className="mb-4">
        Sharkpe.in is provided on an "as-is" and "as-available" basis. We do not guarantee uninterrupted or error-free access to the website. To the fullest extent permitted by law, we disclaim liability for any direct, indirect, or consequential damages arising from your use of our platform.
      </p>

      <h2 className="text-2xl font-semibold mb-3">9. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify and hold Sharkpe.in harmless from any claims, liabilities, or expenses (including legal fees) arising from your use of the platform or violation of these terms.
      </p>

      <h2 className="text-2xl font-semibold mb-3">10. Third-Party Links</h2>
      <p className="mb-4">
        Our website may contain links to third-party websites or services. We are not responsible for the content, policies, or practices of these third-party platforms.
      </p>

      <h2 className="text-2xl font-semibold mb-3">11. Termination</h2>
      <p className="mb-4">
        We reserve the right to terminate or suspend your access to Sharkpe.in at our sole discretion, without prior notice, for any violation of these terms.
      </p>

      <h2 className="text-2xl font-semibold mb-3">12. Governing Law</h2>
      <p className="mb-4">
        These Terms and Conditions are governed by the laws of Indian Jurisdiction. Any disputes arising shall be subject to the exclusive jurisdiction of the courts in Delhi.
      </p>

      <h2 className="text-2xl font-semibold mb-3">13. Changes to Terms and Conditions</h2>
      <p className="mb-4">
        We may update these Terms and Conditions from time to time. The revised version will be posted on this page with an updated "Effective Date." Continued use of the website constitutes your acceptance of the changes.
      </p>

      <h2 className="text-2xl font-semibold mb-3">14. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about these Terms and Conditions, please contact us at:
      </p>
      <ul className="list-none">
      <li><strong>Email:</strong> info@sharkpe.in</li>
              <li><strong>Phone:</strong> +919717664203</li>
      </ul>
    </div>
    <Footer/>
    </>
  );
}

export default TermsConditions;

import React from 'react';
import Navbar from '../components/Navbar';

function RequestDemo() {
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-6">
        <Navbar/>
      <div className="max-w-5xl mx-auto">
        {/* Page Title */}
        <h1 className="text-4xl font-bold text-blue-700 text-center mb-8">
          Request a Demo
        </h1>
        <p className="text-lg text-gray-600 text-center mb-12">
          Fill out the form below, and our team will get in touch with you shortly to schedule your demo.
        </p>

        {/* Form Section */}
        <div className="bg-white shadow-md rounded-lg p-8">
          <form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Name Field */}
              <div>
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Your Name"
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Email Field */}
              <div>
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Your Email"
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Phone Number Field */}
              <div>
                <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Your Phone Number"
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Company Name Field */}
              <div>
                <label className="block text-gray-700 font-medium mb-2" htmlFor="company">
                  Company Name
                </label>
                <input
                  type="text"
                  id="company"
                  placeholder="Your Company Name"
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Message Field */}
            <div className="mt-6">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                Additional Details
              </label>
              <textarea
                id="message"
                rows="5"
                placeholder="Tell us more about your requirements"
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-3 mt-6 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Submit Request
            </button>
          </form>
        </div>

        {/* Contact Info Section */}
        <div className="text-center mt-12">
          <p className="text-gray-600">
            Need help? Contact us at{' '}
            <a href="mailto:info@sharkpe.in" className="text-blue-500 underline">
              info@sharkpe.in
            </a>{' '}
            or call us at +91 9717664203.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RequestDemo;

import React, { useState } from 'react';
import image23 from '../assets/image23.png';
import image22 from '../assets/image22.png';
import image24 from '../assets/image24.png';
import Reconciliations from '../assets/Reconciliations.png';
import Routes from '../assets/Routes.png';
import Plugin from '../assets/Plugin.png';
import Dashboard from '../assets/Dashboard.png';
import PG from '../assets/PG.png';
import Insights from '../assets/Insights.png';
import transaction from '../assets/transaction.png';
import Sdk from '../assets/Sdk.png'
import Extensions from '../assets/Extensions.png'
import api from '../assets/api.png'
import { FaArrowRightToBracket } from "react-icons/fa6";
import icic from '../assets/icons/icic.png';
import canra from '../assets/icons/canra.png';
import idfc from '../assets/icons/idfc.png';
import bnk from '../assets/icons/bnk.png';
import badoda from '../assets/icons/badoda.png';
import axis from '../assets/icons/axis.png';
import bhim from '../assets/icons/bhim.png';
import gpay from '../assets/icons/gpay.png';
import images from '../assets/icons/images.png';
import paytm from '../assets/icons/paytm.png';
import phonepe from '../assets/icons/phonepe.png';
import png from '../assets/icons/png.png';
import pngwi from '../assets/icons/pngwi.png';
import pngwin from '../assets/icons/pngwin.png';
import pngwing from '../assets/icons/pngwing.png';


function PaymentSection() {
    const [activeButton, setActiveButton] = useState("Integrate");
    const [activeSection, setActiveSection] = useState("Integrate");

    const handleButtonClick = (sectionName) => {
        setActiveSection(sectionName);
    };


    return (
        <div className="p-8 md:p-16 lg:p-24">
          <header className="text-center mb-12 px-4">
  <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-purple-600">
    Full payment stack
  </h1>
  <p className="text-base sm:text-lg md:text-xl text-gray-600 mt-4 max-w-3xl mx-auto">
    Revolutionize your payment process with a comprehensive, seamless payment solution that streamlines your operations and enables rapid business expansion.
  </p>
  <div className="flex flex-wrap justify-center mt-6 gap-4 sm:gap-6 lg:gap-10">
  {["Integrate", "Optimize", "Manage", "Develop"].map((buttonName) => (
    <button
      key={buttonName}
      onClick={() => handleButtonClick(buttonName)}
      className={`px-4 sm:px-6 py-2 border border-black rounded-3xl shadow-md shadow-gray-400 cursor-pointer transition duration-300 ${
        activeSection === buttonName
          ? "bg-gray-800 text-white"
          : "bg-white text-gray-800 hover:bg-gray-400 hover:text-black"
      }`}
    >
      {buttonName}
    </button>
  ))}
</div>

</header>


            {/* Conditionally Render Section */}
            {activeSection === "Integrate" && (
             <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 mb-12 px-4 sm:px-6 lg:px-8">
             {[
               {
                 image: image23,
                 title: "Payment Providers",
                 description:
                   "You can easily choose and connect with any payment service provider (PSP) in just a few clicks. Our platform currently supports over 25+ payment gateways, both domestic and international, and we are continually adding more options.",
                 link: "#",
               },
               {
                 image: image24,
                 title: "Payouts",
                 description:
                   "Transfer funds to any bank account, UPI ID, debit card, credit card, or digital wallet. Simplify your payment process by automating transactions with our user-friendly interface and seamless APIs. You can also make cross-platform payouts with ease.",
                 link: "#",
               },
               {
                 image: image22,
                 title: "Payment Methods",
                 description:
                   "Our payment acceptance capabilities span across the globe with support for over 100 payment methods. This allows organizations to dynamically enable, disable, or prioritize payment methods in real-time.",
                 link: "#",
               },
             ].map((item, index) => (
               <div
                 key={index}
                 className="p-6 rounded-lg flex flex-col sm:flex-row items-center sm:items-start gap-6 hover:bg-gray-100 transition duration-300 cursor-pointer"
               >
                 <img
                   src={item.image}
                   alt={item.title}
                   className="w-24 sm:w-1/3 hover:scale-105 transition-transform duration-300"
                 />
                 <div>
                   <h3 className="text-lg sm:text-xl font-semibold mb-4">{item.title}</h3>
                   <p className="text-sm sm:text-base text-gray-600 mb-4">{item.description}</p>
                   <a
                     href={item.link}
                     className="text-purple-600 font-semibold inline-flex items-center hover:underline"
                   >
                     Read More
                   </a>
                 </div>
               </div>
             ))}
           </section>
           
            )}
            {activeSection === "Optimize" && (
               <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-12 px-4 sm:px-6 lg:px-8 bg-slate-100">
               {[
                 {
                   image: Routes,
                   title: "Payment Providers",
                   description:
                     "You can easily choose and connect with any payment service provider (PSP) in just a few clicks. Our platform currently supports over 25+ payment gateways, both domestic and international, and we are continually adding more options.",
                 },
                 {
                   image: Plugin,
                   title: "Payment Providers",
                   description:
                     "You can easily choose and connect with any payment service provider (PSP) in just a few clicks. Our platform currently supports over 25+ payment gateways, both domestic and international, and we are continually adding more options.",
                   reverse: true,
                 },
                 {
                   image: Reconciliations,
                   title: "Payment Methods",
                   description:
                     "Our payment acceptance capabilities span across the globe with support for over 100 payment methods. This allows organizations to dynamically enable, disable, or prioritize payment methods in real-time.",
                 },
               ].map((item, index) => (
                 <div
                   key={index}
                   className={`p-6 rounded-lg flex flex-col md:flex-row items-center gap-6 ${
                     item.reverse ? "flex-col-reverse md:flex-row-reverse" : ""
                   } hover:bg-gray-100 transition duration-300`}
                 >
                   <img
                     src={item.image}
                     alt={item.title}
                     className="w-full md:w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                   />
                   <div className="text-center md:text-left">
                     <h3 className="text-lg md:text-xl font-semibold mb-4">{item.title}</h3>
                     <p className="text-sm md:text-base text-gray-600 mb-4">{item.description}</p>
                     <a
                       href="#"
                       className="text-purple-600 font-semibold inline-flex items-center hover:underline"
                     >
                       Read More <FaArrowRightToBracket className="ml-2" />
                     </a>
                   </div>
                 </div>
               ))}
             </section>
             
            )}
            {activeSection === "Manage" && (
              <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-12 px-4 sm:px-6 lg:px-8 bg-slate-100">
              {[
                {
                  image: Dashboard,
                  title: "Dashboard",
                  description:
                    "Access all your online and offline transactions through a single dashboard. Our intelligent dashboard allows you to monitor OMNI channel payments from a centralized location, providing you with a comprehensive 360-degree view of your business.",
                },
                {
                  image: transaction,
                  title: "Complete Transaction Details",
                  description:
                    "All information collected by your payment gateway/payment aggregator (PG/PA) is presented to you in a comprehensive transaction overview. This data can also be downloaded as management information system (MIS) or automatically generated at the end of each month.",
                },
                {
                  image: PG,
                  title: "All PG/PA Management",
                  description:
                    "Manage all your payment gateways/payment aggregators (PGs/PAs) from a centralized location. This feature provides you with more flexibility, such as the ability to enable or disable payment methods and process refunds from a single location.",
                },
                {
                  image: Insights,
                  title: "Data Insights",
                  description:
                    "Gain valuable insights and effortlessly track key performance indicators with our Data Insights feature. Effortlessly locate specific transactions with our easy-to-use search parameters. Manage accounts, monitor integrations, handle payments and refunds, and more, all within one powerful interface for comprehensive data management.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="p-6 rounded-lg flex flex-col md:flex-row items-center gap-6 hover:bg-gray-100 transition duration-300"
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full md:w-1/2 hover:scale-105 hover:shadow-lg transition-transform duration-300 rounded-lg"
                  />
                  <div className="text-center md:text-left">
                    <h3 className="text-lg md:text-xl font-semibold mb-4">{item.title}</h3>
                    <p className="text-sm md:text-base text-gray-600 mb-4">{item.description}</p>
                    <a
                      href="#"
                      className="text-purple-600 font-semibold inline-flex items-center hover:underline"
                    >
                      Read More <FaArrowRightToBracket className="ml-2" />
                    </a>
                  </div>
                </div>
              ))}
            </section>
            
            )}
            {activeSection === "Develop" && (
           <section className="bg-slate-100 py-16  md:px-20  px-4 sm:px-6 lg:px-8">
           {/* Content Sections */}
           <div className="mt-12 space-y-16">
             {/* SDKs Section */}
             <div className="flex flex-col md:flex-row items-start gap-6 md:gap-10 m-8 md:m-16 hover:bg-gray-100 cursor-pointer hover:scale-105 hover:shadow-lg transition-transform duration-300">
               <img
                 src={Sdk}
                 alt="SDK Icon"
                 className="w-12 h-12 mt-1 flex-shrink-0"
               />
               <div>
                 <h3 className="text-lg sm:text-xl font-semibold text-black mb-4">
                   SDKs
                 </h3>
                 <hr className="my-4 border-gray-300" />
                 <p className="text-gray-800 dark:text-gray-700">
                   Our SDKs make it easier to integrate payment functionality into your platforms. These SDKs provide pre-built code and libraries that can be easily integrated into an application, reducing the time and effort required to build payment functionality from scratch.
                 </p>
               </div>
             </div>
         
             {/* Extensions Section */}
             <div className="flex flex-col md:flex-row items-start gap-6 hover:bg-gray-100 md:gap-10 m-8 md:m-16 cursor-pointer hover:scale-105 hover:shadow-lg transition-transform duration-300">
               <img
                 src={Extensions}
                 alt="Extensions Icon"
                 className="w-12 h-12 mt-1 flex-shrink-0"
               />
               <div>
                 <h3 className="text-lg sm:text-xl font-semibold text-black mb-4">
                   Extensions
                 </h3>
                 <hr className="my-4 border-gray-300" />
                 <p className="text-gray-800">
                   Our payment extensions can be used to add new payment functionality to your applications. These extensions can be used to customize the payment experience for customers, provide new payment options, and more.
                 </p>
               </div>
             </div>
         
             {/* API Playground Section */}
             <div className="flex flex-col md:flex-row items-start gap-6 hover:bg-gray-100 md:gap-10 m-8 md:m-16 cursor-pointer hover:scale-105 hover:shadow-lg transition-transform duration-300">
               <img
                 src={api}
                 alt="API Playground Icon"
                 className="w-12 h-12 mt-1 flex-shrink-0 "
               />
               <div>
                 <h3 className="text-lg sm:text-xl font-semibold text-black mb-4">
                   API Playground
                 </h3>
                 <hr className="my-4 border-gray-300" />
                 <p className="text-gray-800 mb-4">
                   Our API Playground can be used to test and explore payment functionality without having to write any code. This can be useful for integrating payment functionality into your applications, as it allows you to quickly and easily test different scenarios and validate the functionality of the payment API.
                 </p>
                 <a
                   href="#"
                   className="text-purple-600 font-semibold inline-flex items-center hover:underline"
                 >
                   Read More <span className="ml-2">→</span>
                 </a>
               </div>
             </div>
           </div>
         </section>
         
           
            )}
            {/* Add content for other buttons if needed */}
            {activeButton !== "Integrate" && (
                <div className="text-center text-gray-600 mt-8">
                    <p>{activeButton} section is under development.</p>
                </div>
            )}

            {/* Logos Section */}
            <section className="flex justify-center items-center overflow-hidden h-28 ">
                <div className="flex animate-scroll-left gap-0">
                    {/* Duplicate the logos */}
                    {[...Array(2)].map((_, idx) => (
                        <React.Fragment key={idx}>
                            <img src={icic} alt="Logo 1" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={canra} alt="Logo 2" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={idfc} alt="Logo 3" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={bnk} alt="Logo 4" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={badoda} alt="Logo 5" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={axis} alt="Logo 6" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={bhim} alt="Logo 7" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={gpay} alt="Logo 8" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={images} alt="Logo 9" className="w-20 h-20 border-gray-300 border rounded-full" />
                            <img src={paytm} alt="Logo 10" className="w-20 h-20 border-gray-300 border rounded-full" />
                        </React.Fragment>
                    ))}
                </div>
            </section>




        </div>
    );
}

export default PaymentSection;

import React from 'react';
import logo from '../assets/logo.png';

const Footer = () => {
    return (
        <footer className="px-4 py-6 bg-[#E5EBFF] border-t-2 lg:px-10">
            {/* Main Footer Section */}
            <div className="block mx-auto sm:text-left sm:flex gap-10  mb-8 ">
                {/* Logo Section */}
                <div className='w-1/5 mx-auto'>
                    <div className="sm:col-span-2 text-center sm:text-left">
                        <a href="#" className="inline-flex items-center justify-center sm:justify-start">
                            <img src={logo} alt="Sharkpe logo" className="h-10" />
                        </a>
                    </div>
                </div>

                <div className='block sm:flex justify-between w-4/5'>
                    <div className="flex flex-col gap-2 text-sm text-left">
                        <p className="text-base font-bold tracking-wide text-gray-900">Manage</p>
                        <a href="#" className="hover:text-blue-600">Dashboard</a>
                        <a href="#" className="hover:text-blue-600">All PG/PA management</a>
                        <a href="#" className="hover:text-blue-600">Complete transaction details</a>
                        <a href="#" className="hover:text-blue-600">Data Insights</a>

                    </div>

                    <div className="flex flex-col gap-2 text-sm text-left">
                        <p className="text-base font-bold tracking-wide text-gray-900">Develop</p>
                        <a href="#" className="hover:text-blue-600">SDKs</a>
                        <a href="#" className="hover:text-blue-600">Extensions</a>
                        <a href="#" className="hover:text-blue-600">API Playground</a>
                    </div>

                    {/* Useful Links Section */}
                    <div className="flex flex-col gap-2 text-sm     text-left">
                        <p className="text-base font-bold tracking-wide text-gray-900">Useful Links</p>
                        <a href="/ContactUs" className="hover:text-blue-600">Contact Us</a>
                        <a href="#" className="hover:text-blue-600">FAQ</a>
                        <a href="#" className="hover:text-blue-600">Blogs</a>
                        <a href="#" className="hover:text-blue-600">Career</a>
                        <a href="/TermsConditions" className="hover:text-blue-600">Terms & Conditions</a>
                        <a href="/privacy-policy" className="hover:text-blue-600">Privacy Policy</a>
                    </div>
                </div>
            </div>
            {/* Manage Section */}


            {/* Bottom Footer Section */}
            <div className="flex flex-col-reverse justify-between pt-5 border-t lg:flex-row">
                <p className="text-sm text-gray-600 text-center lg:text-left">© 2024 Sharkpe. All rights reserved.</p>
                <a
                    href="#"
                    className="text-sm text-gray-600 transition-colors duration-300 hover:text-blue-600 text-center lg:text-right"
                >
                    Privacy & Cookies Policy
                </a>
            </div>
        </footer>
    );
};

export default Footer;

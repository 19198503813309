import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Navbar from "../components/Navbar";
import contact from '../assets/contact.jpeg'

function ContactUsPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    communicationMethod: "email",
    message: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <><Navbar /><form onSubmit={handleSubmit} action="https://fabform.io/f/xxxxx" method="post">
      <section className="py-24 ">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 grid-cols-1 animate__animated animate__fadeIn animate__delay-1s gap-8">
            {/* Left Section with Image */}
            <div className="lg:mb-0 mb-10 animate__animated animate__fadeIn animate__delay-2s transform transition duration-500 hover:scale-105 animate__slideInLeft">
  <div className="group w-full h-full">
    <div className="relative h-full">
      {/* Responsive image */}
      <img
        src={contact}
        alt="ContactUs background"
        className="w-full h-full lg:rounded-l-2xl rounded-2xl bg-blend-multiply bg-indigo-700 object-cover"
      />
      <h1 className="font-manrope text-white text-4xl sm:text-3xl md:text-5xl font-bold leading-10 absolute top-11 left-11 sm:left-8 sm:top-8 ">
        Contact Us
      </h1>
      <div className="absolute bottom-0 w-full lg:p-11 p-5">
        <div className="bg-white rounded-lg p-6">
          <a href="javascript:;" className="flex items-center mb-6">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.3092 18.3098C22.0157 18.198 21.8689 18.1421 21.7145 18.1287C21.56 18.1154 21.4058 18.1453 21.0975 18.205L17.8126 18.8416C17.4392 18.9139 17.2525 18.9501 17.0616 18.9206C16.8707 18.891 16.7141 18.8058 16.4008 18.6353C13.8644 17.2551 12.1853 15.6617 11.1192 13.3695C10.9964 13.1055 10.935 12.9735 10.9133 12.8017C10.8917 12.6298 10.9218 12.4684 10.982 12.1456L11.6196 8.72559C11.6759 8.42342 11.7041 8.27233 11.6908 8.12115C11.6775 7.96998 11.6234 7.82612 11.5153 7.5384L10.6314 5.18758C10.37 4.49217 10.2392 4.14447 9.95437 3.94723C9.6695 3.75 9.29804 3.75 8.5551 3.75H5.85778C4.58478 3.75 3.58264 4.8018 3.77336 6.06012C4.24735 9.20085 5.64674 14.8966 9.73544 18.9853C14.0295 23.2794 20.2151 25.1426 23.6187 25.884C24.9335 26.1696 26.0993 25.1448 26.0993 23.7985V21.2824C26.0993 20.5428 26.0993 20.173 25.9034 19.8888C25.7076 19.6046 25.362 19.4729 24.6708 19.2096L22.3092 18.3098Z"
                stroke="#4F46E5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 ">
  <div className="flex items-center ">
    <strong className="mr-2">Phone:</strong>
    <span>+91 9717664203</span>
  </div>

  <div className="flex items-center ">
    <strong className="mr-2">Email:</strong>
    <a
      href="mailto:info@sharkpe.in"
      className="underline hover:text-purple-200"
    >
      info@sharkpe.in
    </a>
  </div>
</div>

          </a>
        </div>
      </div>
    </div>
  </div>
</div>


            {/* Right Section - Form */}
            <div className="bg-gray-50 p-5 lg:p-11 lg:rounded-r-2xl rounded-2xl animate__animated animate__fadeIn animate__delay-3s transform transition duration-500 hover:scale-105 animate-slide-in-left">
              <h2 className="text-indigo-600 font-manrope text-4xl font-semibold leading-10 mb-11">
                Send Us A Message
              </h2>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10 transition duration-300 ease-in-out transform hover:scale-105"
                placeholder="Name" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10 transition duration-300 ease-in-out transform hover:scale-105"
                placeholder="Email" />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full h-12 text-gray-600 placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10 transition duration-300 ease-in-out transform hover:scale-105"
                placeholder="Phone" />
              <div className="mb-10">
                <h4 className="text-gray-500 text-lg font-normal leading-7 mb-4">
                  Preferred method of communication
                </h4>
                <div className="flex">
                  <div className="flex items-center mr-11">
                    <input
                      id="radio-group-1"
                      type="radio"
                      name="communicationMethod"
                      value="email"
                      checked={formData.communicationMethod === "email"}
                      onChange={handleChange}
                      className="hidden checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                    <label
                      htmlFor="radio-group-1"
                      className="flex items-center cursor-pointer text-gray-500 text-base font-normal leading-6"
                    >
                      <span className="border border-gray-300 rounded-full mr-2 w-4 h-4  ml-2 "></span>{" "}
                      Email
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="radio-group-2"
                      type="radio"
                      name="communicationMethod"
                      value="phone"
                      checked={formData.communicationMethod === "phone"}
                      onChange={handleChange}
                      className="hidden checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                    <label
                      htmlFor="radio-group-2"
                      className="flex items-center cursor-pointer text-gray-500 text-base font-normal leading-6"
                    >
                      <span className="border border-gray-300  rounded-full mr-2 w-4 h-4  ml-2 "></span>{" "}
                      Phone
                    </label>
                  </div>
                </div>
              </div>
              <input
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full h-12 text-gray-600 placeholder-gray-400 bg-transparent text-lg shadow-sm font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10 transition duration-300 ease-in-out transform hover:scale-105"
                placeholder="Message" />
              <button
                type="submit"
                className="w-full h-12 text-white text-base font-semibold leading-6 rounded-full transition-all duration-700 hover:bg-indigo-800 bg-indigo-600 shadow-sm"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </section>
      <p className="flex justify-center mb-5 text-center">
        <a
          href="https://www.linkedin.com/in/deeksha-awasthi-4b6433203"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 hover:text-indigo-800 flex items-center text-lg font-medium"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="mr-2 text-indigo-600"
            style={{ fontSize: '1.5em' }} />
          Connect with me on LinkedIn
        </a>
      </p>
    </form></>
  );
}

export default ContactUsPage;
